.floor-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .floor-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 10px; /* Add some space below the text */
  }
  
  .floor-info h3 {
    margin: 0;
  }
  
  .floor-info p {
    margin: 0;
    color: #888;
  }
  
  .status-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .status-buttons button {
    flex: 1; /* Make the buttons take equal space */
    padding: 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .status-buttons button:first-child {
    margin-left: 0;
  }
  
  .status-buttons button:last-child {
    margin-right: 0;
  }
  
  .status-buttons button:hover {
    opacity: 0.9;
  }
  